<template>
  <DataTable
    :total="total"
    :allow-search="true"
    style="font-size: 12px"
    :filter="filter"
    :loading="load || Exportload"
    @reset="filter = { export: 0, removed: 0 }"
    @Refresh="refresh"
    @Export="Export"
  >
    <template v-if="lookups" #filter>
      <b-col cols="12" md="4" class="mb-1">
        <v-select
          v-model="filter.branch_id"
          :filter="fuseSearch"
          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
          :options="lookups.branches"
          label="name"
          class="w-100"
          :reduce="(val) => val.id"
          placeholder="Branch Name"
        />
      </b-col>

      <b-col cols="12" md="4" v-if="logs" class="mb-1">
        <v-select
          v-model="filter.removed"
          :filter="fuseSearch"
          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
          :options="validtionData"
          label="name"
          class="w-100"
          :reduce="(val) => val.id"
          placeholder="Data"
        />
      </b-col>

      <b-col cols="12" md="4" v-if="!logs" class="mb-1">
        <v-select
          v-model="filter.status_id"
          :filter="fuseSearch"
          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
          :options="status"
          label="name"
          class="w-100"
          :reduce="(val) => val.id"
          placeholder="Status "
        />
      </b-col>
      <b-col cols="12" md="4" class="mb-1">
        <v-select
          v-model="filter.patient_type"
          :filter="fuseSearch"
          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
          :options="patient_types"
          label="name"
          class="w-100"
          :reduce="(val) => val.id"
          placeholder="Patient Type "
        />
      </b-col>
      <b-col cols="12" md="4" v-if="!logs" class="mb-1">
        <v-select
          v-model="filter.auditor_id"
          :filter="fuseSearch"
          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
          :options="lookups.reviewers"
          label="name"
          class="w-100"
          :reduce="(val) => val.id"
          placeholder="Assigned Medical Reviewer"
        />
      </b-col>

      <b-col cols="12" md="4" v-if="!logs" class="mb-1">
        <v-select
          v-model="filter.medical_type"
          :filter="fuseSearch"
          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
          :options="medical_types"
          label="name"
          class="w-100"
          :reduce="(val) => val.id"
          placeholder="  Medical Type"
        />
      </b-col>
      <b-col cols="12" md="4" class="mb-1">
        <v-select
          v-model="filter.dr_id"
          :filter="fuseSearch"
          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
          :options="lookups.doctors"
          label="name"
          class="w-100"
          :reduce="(val) => val.id"
          placeholder=" Doctor Name "
        />
      </b-col>

      <b-col cols="12" md="4" class="mb-1"></b-col>
    </template>

    <template #customTable>
      <b-table
        :sort-desc.sync="filter.sort_direction"
        :items="items"
        responsive
        striped
        :fields="fields"
        primary-key="id"
        show-empty
        :empty-text="$t('Global.empty_text')"
        style="font-size: 11px"
      >
        <template #cell(id)="data">
          <b-link
            v-if="hasPermission('show_cases')"
            style="font-weight: bold; font-size: 12px; color: #054978"
            :to="{ name: 'case-show', params: { id: data.item.id } }"
          >
            {{ data.item.id }}
          </b-link>

          <p v-if="!hasPermission('show_cases')">{{ data.item.id }}</p>
        </template>

        <template #cell(name)="data">
          <b-media vertical-align="center" class="d-flex align-items-center">
            <template #aside> </template>
            {{ data.item ? data.item.name : "_" }}
          </b-media>
        </template>

        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${getStatus(data.item.status.status).color}`"
            class="text-capitalize"
          >
            {{ data.item.status.name }}
          </b-badge>
        </template>
        <template #cell(assign_date)="data">
          {{ data.item.assign_date }}
        </template>
        <template #cell(type)="data">
          <span class="text-capitalize">
            {{ data.item.type ? data.item.type.name : "-" }}
          </span>
        </template>
        <template #cell(actions)="data">
          <div class="align-items-center">
            <div>
              <feather-icon
                v-b-tooltip.hover="$t('Global.edit')"
                icon="EditIcon"
                style="cursor: pointer"
                @click="open(data.item.id)"
              />
              <feather-icon
                v-b-tooltip.hover="$t('Global.delete')"
                icon="Trash2Icon"
                style="cursor: pointer"
                class="text-danger"
                v-if="data.item.removed == 0"
                @click="remove(data.item.id)"
              />
              <feather-icon
                v-b-tooltip.hover="$t('Global.restore')"
                icon="RepeatIcon"
                style="cursor: pointer"
                v-else
                @click="restore(data.item.id)"
              />
            </div>
          </div>
        </template>
      </b-table>

      <b-modal
        id="modalPopover"
        v-model="dialog"
        no-close-on-backdrop
        size="lg"
        :title="title"
      >
        <validation-observer ref="simpleRules">
          <b-form :class="load ? 'disabled_all' : ''">
            <div class="row">
              <b-col md="12">
                <b-form-group :label="$t('faculties')" label-for="faculty">
                  <validation-provider
                    #default="{ errors }"
                    name="faculties_ids"
                    rules="required"
                  >
                    <v-select
                      v-model="ruleForm.faculties_ids"
                      :filter="fuseSearch"
                      :label="getSelectLabel()"
                      :reduce="(re) => re.id"
                      :class="errors.length > 0 ? 'custom_invalid' : ''"
                      :options="allLookups ? allLookups.faculties : []"
                      multiple
                    />
                    <small v-if="errors.length" class="text-danger">{{
                      validation(null, 0).message
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </div>
          </b-form>
        </validation-observer>
        <template #modal-footer>
          <b-row :class="load ? 'disabled_all' : ''">
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="submit"
              >
                {{ $t("Global.save") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="dialog = false"
              >
                {{ $t("Global.cancel") }}
              </b-button>
            </b-col>
          </b-row>
        </template>
      </b-modal>
    </template>
    <template #action>
      <b-button
        v-b-tooltip.hover="$t('Global.export_excel')"
        class="btn-icon"
        variant="primary"
        @click="filter.export = 1"
      >
        <feather-icon icon="FileTextIcon" />
      </b-button>
      <!-- <b-button v-b-tooltip.hover="$t('Global.save_state')" class="btn-icon" variant="info" @click="filter.export=1">
          <feather-icon icon="SaveIcon" />
        </b-button> -->
    </template>
  </DataTable>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormDatepicker,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
  BCardHeader,
  BCardBody,
  BModal,
  VBModal,
  BPopover,
  BFormGroup,
  BForm,
} from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, email } from "@validations";
import DataTable from "@/views/components/table/DataTable";

export default {
  components: {
    DataTable,
    BFormGroup,
    BFormDatepicker,
    BCard,
    vSelect,
    BRow,
    BCol,
    BOverlay,
    BCardHeader,
    BCardBody,
    BForm,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    VBModal,
    BPopover,
    ValidationProvider,
    ValidationObserver,
    localize,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      Exportload: false,
      check: true,
      validtionData: [
        {
          id: 0,
          name: " Valid Cases",
        },
        {
          id: 1,
          name: "Rejected (Auto-Checks)",
        },
      ],
      status: [
        {
          id: "0",
          name: "New",
        },
        {
          id: 1,
          name: "Waitng branch feedback",
        },
        {
          id: 2,
          name: "Updated",
        },
        {
          id: 3,
          name: "Closed",
        },
      ],
      patient_types: [
        {
          id: "I",
          name: "Inpatient",
        },
        {
          id: "O",
          name: "Outpatient",
        },
      ],
      medical_types: [
        {
          id: 2,
          name: "Dental Cases",
        },
        {
          id: 1,
          name: "Eye Cases",
        },
      ],
      lookupModules: {
        faculties: true,
        bylaws: true,
        programs: true,
      },
      allLookups: null,
      dialog: false,
      filter: { removed: 0, export: 0, keywords: "" },
      ruleForm: {
        faculties_ids: [],
      },
      user_id: null,
      user_name: null,
      isFilterLoaded: false,
      // search:''
    };
  },
  props: {
    logs: {
      type: Boolean,
      default: false,
    },

    statis: false,
  },

  computed: {
    ...mapGetters({
      items: "cases/items",
      total: "cases/total",
      load: "cases/load",
      lookups: "cases/lookups",
    }),
    title() {
      if (this.user_name) {
        return (
          this.$t("Global.userAccessControl") + " ( " + this.user_name + " )"
        );
      } else {
        return this.$t("Global.userAccessControl");
      }
    },
    fields() {
      let fields = [
        {
          key: "branch.name",
          label: "Branch",
          sortable: true,
        },
        {
          key: "branch.code",
          label: "Branch Code",
          sortable: true,
        },
        {
          key: "id",
          label: "Case ID",
          sortable: true,
        },
        {
          key: "medical_record",
          label: "MRN",
          sortable: true,
        },
        {
          key: "name",
          label: "Patient",
          sortable: true,
        },

        {
          key: "dr_name",
          label: "Doctor",
          sortable: true,
        },

        {
          key: "account_no",
          label: "Account no",
          sortable: true,
        },
        // {
        //   key: 'invoice[0].serial_no',
        //   label: 'MRN',
        //   sortable: true,
        // },

        {
          key: "status",
          label: "status",
          sortable: true,
        },
        // {
        //   key: 'final_bill_date',
        //   label: ' Discharge Date',
        //   sortable: true,
        // },
        {
          key: "admission_date",
          label: " Admission Date",
          sortable: true,
        },

        {
          key: "close_date",
          label: "Close date",
          sortable: true,
        },

        {
          key: "patient_type",
          label: "Patient type ",
          sortable: true,
        },
        {
          key: "assign_date",
          label: "Assign Date ",
          sortable: true,
        },
        {
          key: "auditor.name",
          label: "Reviewer",
          sortable: true,
        },
      ];
      if (this.logs == true) {
        fields = [
          //   {
          //   key: 'id',
          //   label: 'Case ID',
          //   sortable: true,
          // },
          {
            key: "name",
            label: "Patient",
            sortable: true,
          },

          {
            key: "dr_name",
            label: "Doctor",
            sortable: true,
          },

          {
            key: "medical_record",
            label: "MRN",
            sortable: true,
          },

          {
            key: "branch.name",
            label: "Branch",
            sortable: true,
          },
          {
            key: "account_no",
            label: "Account no",
            sortable: true,
          },
          {
            key: "patient_type",
            label: "Patient type ",
            sortable: true,
          },
          {
            key: "assign_date",
            label: "Recieved Date ",
            sortable: true,
          },
          {
            key: "settings.filter",
            label: "Rejection Reason",
          },
          {
            key: "auditor.name",
            label: "Reviewer",
            sortable: true,
          },
        ];
      }
      return fields;
    },
  },
  watch: {
    filter: {
      handler(newFilter) {
        this.saveFilterState();
        this.refresh(newFilter);
      },
      deep: true,
    },
  },
  created() {
    this.loadFilterState();
  },

  mounted() {
    if (this.isFilterLoaded) {
      this.refresh(this.filter);
    }
  },

  beforeDestroy() {
    // Save the filter state before the component is destroyed
    this.saveFilterState();
  },
  methods: {
    // ... Your existing methods ...

    saveFilterState() {
      this.filter.keywords = this.filter.search;
      localStorage.setItem("datatableFilterState", JSON.stringify(this.filter));
      // this.$swal({
      //   icon: 'success',
      //   title: 'Filter state saved!',
      //   showConfirmButton: false,
      //   timer: 1500,
      // });
    },
    loadFilterState() {
      const savedFilterState = localStorage.getItem("datatableFilterState");
      // const savedFilterState = localStorage.getItem("datatableFilterState");
      if (savedFilterState) {
        this.filter = JSON.parse(savedFilterState);
        this.isFilterLoaded = true;
      }

      // this.refresh(this.filter);
    },

    ...mapActions({
      getLookups: "app/GET_LOOKUPS",
      setAccessControl: "users/setAccessControl",
    }),
    fetchLookups() {
      this.getLookups(this.lookupModules).then((data) => {
        this.allLookups = data.success;
      });
    },

    openDialog(item) {
      this.user_id = item.id;
      this.user_name = item.name;
      this.ruleForm.faculties_ids = item?.access?.faculties_ids;
      this.dialog = true;
    },
    open(id) {
      window.open(
        this.$router.resolve({ name: "user-edit", params: { id } }).href,
        "_blank"
      );
    },
    refresh(query) {
      console.log("search", this.filter.search);
      console.log("dfr", this.filter);
      query = { ...query, type: this.type };
      this.$store.dispatch("cases/cases", { query });
    },
    submit() {
      this.$refs.simpleRules
        .validate()
        .then((success) => {
          if (success) {
            const payload = {
              query: { faculties_ids: this.ruleForm.faculties_ids },
              id: this.user_id,
            };
            this.setAccessControl(payload)
              .then((response) => {
                this.$swal({
                  icon: "success",
                  text: `${this.$t("Global.successMessage")}`,
                  showConfirmButton: false,
                  timer: 2000,
                });
                this.refresh();
                this.dialog = false;
                this.user_id = null;
                this.user_name = null;
              })
              .catch((error) => {
                // this.$toast({
                //   component: ToastificationContent,
                //   position: 'top-right',
                //   props: {
                //     title: 'Error',
                //     variant: 'danger',
                //     text: `${error || $t('Global.errorMessage')}`,
                //   },
                // })
                this.dialog = false;
                this.user_id = null;
                this.user_name = null;
              });
          }
        })
        .catch((_) => {
          const query = {
            payload: { faculties_ids: this.ruleForm.faculties_ids },
            id: this.user_id,
          };
          this.setAccessControl({ query })
            .then((response) => {
              this.$swal({
                icon: "success",
                text: `${this.$t("Global.successMessage")}`,
                showConfirmButton: false,
                timer: 2000,
              });
              this.refresh();
              this.dialog = false;
              this.user_id = null;
            })
            .catch((error) => {
              // this.$toast({
              //   component: ToastificationContent,
              //   position: 'top-right',
              //   props: {
              //     title: 'Error',
              //     variant: 'danger',
              //     text: `${error || $t('Global.errorMessage')}`,
              //   },
              // })
              this.dialog = false;
              this.user_id = null;
            });
        });
    },
    Export(query) {
      query = { ...query, language: this.$i18n.locale };
      if (this.total) {
        query = { ...query };
      }

      // if (this.department_id) {
      //   query = { ...query, department_id: this.department_id };
      // }
      this.Exportload = true;
      this.$store.dispatch("cases/export", query).then((_) => {
        console.log("load", this.Exportload);
        this.filter.export = 0;
        this.Exportload = false;
      });
    },
    remove(userId) {
      this.$swal({
        title: `${this.$t("Global.deleteTitle")}`,
        text: `${this.$t("Global.deleteText")}`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: `${this.$t("Global.cancel")}`,
        confirmButtonText: `${this.$t("Global.deleteBtn")}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("users/remove", userId).then((_) => {
            this.refresh();
            this.$swal({
              icon: "success",
              text: this.$t("Global.deletedUser"),
              showConfirmButton: false,
              timer: 1500,
            });
          });
        }
      });
    },

    open_role(id) {
      window.open(
        this.$router.resolve({ name: "user-role", params: { id: id } }).href,
        "_blank"
      );
    },
    restore(userId) {
      this.$swal({
        title: `${this.$t("Global.deleteTitle")}`,
        text: "You Will Activate User!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: `${this.$t("Global.cancel")}`,
        confirmButtonText: "Yes, Activate!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("users/restore", userId).then((_) => {
            this.refresh();
            this.$swal({
              icon: "success",
              title: "Activated!",
              text: "User has been Activated.",
              showConfirmButton: false,
              timer: 1500,
            });
          });
        }
      });
    },
  },
  mounted() {
    this.loadFilterState();
    // this.fetchLookups();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
th {
  color: blue;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
