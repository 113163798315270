<template>
<div>
 

  <DataTable :total="patient_total" :allow-search="true" :filter="filter" :loading="load" @reset="filter = { export: 0, removed: 0 }" @Refresh="refresh">

  
   
    <template v-if="lookups" #filter>
      <b-col cols="12" md="4">
        <v-select v-model="filter.branch_id" :filter="fuseSearch" :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'" :options="lookups.branches" label="name" class="w-100" :reduce="val => val.id" placeholder="Branch Name" />
      </b-col>
    </template>


    <template #customTable>
      <b-table :sort-desc.sync="filter.sort_direction"  style="font-size:11px;" :items="patient_items" responsive striped :fields="patient_fields" primary-key="id" show-empty :empty-text="$t('Global.empty_text')">
      
       <template #cell(patient_name)="data">
          <b-link 
          style="font-weight:bold ; color: #054978"

          :to="{ name: 'patient-show', params: { id: data.item.id } }">
            {{data.item.patient_name}}
            </b-link>
        </template>
      
      
             <template #cell(medical_record)="data">
          <b-link 
          style="font-weight:bold ; color: #054978"

          :to="{ name: 'patient-show', params: { id: data.item.id } }">
            {{data.item.medical_record}}
            </b-link>
        </template>
       


    </b-table>
  
    </template>
  </DataTable>

</div>



</template>

<script>
   
import { BCard, BRow, BFormCheckbox, BFormCheckboxGroup,BCol,BFormDatepicker , BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BCardHeader, BCardBody, BModal, VBModal, BPopover, BFormGroup, BForm } from 'bootstrap-vue';
import { mapGetters, mapActions } from 'vuex';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import { avatarText } from '@core/utils/filter';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
import { required, email } from '@validations';
import DataTable from '@/views/components/table/DataTable';

export default {
  components: {
    DataTable,
    BFormGroup,
    BFormDatepicker,
    BFormCheckbox,
    BFormCheckboxGroup,
    BCard,
    vSelect,
    BRow,
    BCol,
    BOverlay,
    BCardHeader,
    BCardBody,
    BForm,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    VBModal,
    BPopover,
    ValidationProvider,
    ValidationObserver,
    localize,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selected:null,
      cases_view: false,
      options:[],
      status:[
        {
          id : 0 ,
          name : "New"
        },  {
          id : 1 ,
          name : "Waitng branch feedback"
        },  {
          id : 2 ,
          name : "Updated"
        },  {
          id : 3 ,
          name : "Closed"
        }
      ],
         patient_types:[
        {
          id : 0 ,
          name : "Inpatient"
        },  {
          id : 1 ,
          name : "Outpatient"
        }
      ],
      lookupModules: {
        faculties: true,
        bylaws: true,
        programs: true,
      },
      allLookups: null,
      dialog: false,
      filter: { removed: 0 },
      ruleForm: {
        faculties_ids: [],
      },
      user_id: null,
      user_name: null,
    };
  },
  computed: {
    ...mapGetters({
   
      patient_items: 'patients/items',
      patient_total: 'patients/total',
      patient_load: 'patients/load',
      lookups: 'patients/lookups',
    }),
    title() {
      if (this.user_name) {
        return this.$t('Global.userAccessControl') + ' ( ' + this.user_name + ' )';
      } else {
        return this.$t('Global.userAccessControl');
      }
    },
    fields() {

      let fields = [
          {
          key: 'id',
          label: 'Case ID',
          sortable: true,
        },
         {
          key: 'name',
          label: 'Patient',
          sortable: true,
        },
    
        {
          key: 'doctor.name',
          label: "Doctor",
          sortable: true,
        },
      

        {
          key: 'visit_no',
          label: 'Visit no',
          sortable: true,
        },
        {
          key: 'invoice[0].serial_no',
          label: 'MRN',
          sortable: true,
        },
        {
             key: 'medical_record',
          label: 'Invoice no',
          sortable: true,
        },
          {
          key: 'status',
          label: 'status',
          sortable: true,
        },
         {
          key: 'close_date',
          label: 'Close date',
          sortable: true,
        },
         {
          key: 'branch.name',
          label: 'Branch',
          sortable: true,
        },
           {
          key: 'patient_type.name',
          label: 'Patient type ',
          sortable: true,
        },
            {
          key: 'assign_date',
          label: 'Assign Date ',
          sortable: true,
        },
         {
          key: 'auditor.name',
          label: "Reviewer",
          sortable: true,
        }
 
      ];

      return fields;
    },

    patient_fields() {

let patient_fields = [

   {
    key: 'last_branch',
    label: 'Branch Name',
    sortable: true,
  },
     {
    key: 'last_branch_code',
    label: 'Branch Code',
    sortable: true,
  },
   {
    key: 'patient_name',
    label: 'Patient Name',
    sortable: true,
  },
  {
       key: 'medical_record',
    label: ' Medical Record',
    sortable: true,
  },
  
 
  

];

return patient_fields;
},
  },


  methods: {
    ...mapActions({
      getLookups: 'app/GET_LOOKUPS',
      setAccessControl: 'users/setAccessControl',
    }),
    fetchLookups() {
      this.getLookups(this.lookupModules).then(data => {
        this.allLookups = data.success;
      });
    },
    openDialog(item) {
      this.user_id = item.id;
      this.user_name = item.name;
      this.ruleForm.faculties_ids = item?.access?.faculties_ids;
      this.dialog = true;
    },
    open(id) {
      window.open(this.$router.resolve({ name: 'user-edit', params: { id } }).href, '_blank');
    },
    refresh(query) {
      query = { ...query, type: this.type };
      this.$store.dispatch('cases/cases', { query });
      this.$store.dispatch('patients/patients', { query });
    },
    submit() {
      this.$refs.simpleRules
        .validate()
        .then(success => {
          if (success) {
            const payload = {
              query: { faculties_ids: this.ruleForm.faculties_ids },
              id: this.user_id,
            };
            this.setAccessControl(payload)
              .then(response => {
                this.$swal({
                  icon: 'success',
                  text: `${this.$t('Global.successMessage')}`,
                  showConfirmButton: false,
                  timer: 2000,
                });
                this.refresh();
                this.dialog = false;
                this.user_id = null;
                this.user_name = null;
              })
              .catch(error => {
                // this.$toast({
                //   component: ToastificationContent,
                //   position: 'top-right',
                //   props: {
                //     title: 'Error',
                //     variant: 'danger',
                //     text: `${error || $t('Global.errorMessage')}`,
                //   },
                // })
                this.dialog = false;
                this.user_id = null;
                this.user_name = null;
              });
          }
        })
        .catch(_ => {
          const query = {
            payload: { faculties_ids: this.ruleForm.faculties_ids },
            id: this.user_id,
          };
          this.setAccessControl({ query })
            .then(response => {
              this.$swal({
                icon: 'success',
                text: `${this.$t('Global.successMessage')}`,
                showConfirmButton: false,
                timer: 2000,
              });
              this.refresh();
              this.dialog = false;
              this.user_id = null;
            })
            .catch(error => {
              // this.$toast({
              //   component: ToastificationContent,
              //   position: 'top-right',
              //   props: {
              //     title: 'Error',
              //     variant: 'danger',
              //     text: `${error || $t('Global.errorMessage')}`,
              //   },
              // })
              this.dialog = false;
              this.user_id = null;
            });
        });
    },
    remove(userId) {
      this.$swal({
        title: `${this.$t('Global.deleteTitle')}`,
        text: `${this.$t('Global.deleteText')}`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: `${this.$t('Global.cancel')}`,
        confirmButtonText: `${this.$t('Global.deleteBtn')}`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('users/remove', userId).then(_ => {
            this.refresh();
            this.$swal({
              icon: 'success',
              text: this.$t('Global.deletedUser'),
              showConfirmButton: false,
              timer: 1500,
            });
          });
        }
      });
    },

    open_role(id) {
      window.open(this.$router.resolve({ name: 'user-role', params: { id: id } }).href, '_blank');
    },
    restore(userId) {
      this.$swal({
        title: `${this.$t('Global.deleteTitle')}`,
        text: 'You Will Activate User!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: `${this.$t('Global.cancel')}`,
        confirmButtonText: 'Yes, Activate!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('users/restore', userId).then(_ => {
            this.refresh();
            this.$swal({
              icon: 'success',
              title: 'Activated!',
              text: 'User has been Activated.',
              showConfirmButton: false,
              timer: 1500,
            });
          });
        }
      });
    },
  },
  mounted() {
    // this.fetchLookups();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
th {
  color: blue;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
