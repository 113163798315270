import { render, staticRenderFns } from "./patients.vue?vue&type=template&id=a9eecb16&scoped=true&"
import script from "./patients.vue?vue&type=script&lang=js&"
export * from "./patients.vue?vue&type=script&lang=js&"
import style0 from "./patients.vue?vue&type=style&index=0&id=a9eecb16&lang=scss&scoped=true&"
import style1 from "./patients.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9eecb16",
  null
  
)

export default component.exports